import React from 'react';

const AboutSection = () => {
  return (
    <div className="relative w-full bg-gray-900 text-white p-6 rounded-lg shadow-xl mb-12">
      <div className="flex flex-col lg:flex-row items-start gap-8 max-w-7xl mx-auto">
        {/* Text Content */}
        <div className="flex-1 z-10">
          <h1 className="text-white text-4xl sm:text-5xl md:text-6xl font-['DM_Serif_Display'] tracking-tight">Who am I?</h1>
          <p className="text-lg text-gray-300 mb-4 mt-6 font-inter leading-relaxed">
            Marcus Chau is a tech entrepreneur, founder, and software developer based in New York City 
            and Dallas. With a strong foundation in both business and technology, Marcus has founded 
            multiple ventures including a successful tutoring company, while continuing to build 
            innovative technical solutions across various sectors.
          </p>
          <p className="text-lg text-gray-300 font-inter leading-relaxed">
            As a passionate technologist and founder, Marcus specializes in developing innovative 
            software solutions and building scalable applications. His work focuses on creating 
            efficient, user-centric platforms that solve complex problems, drawing from his unique 
            experience in both educational services and technology development.
          </p>
        </div>

        {/* Image Section */}
        <div className="lg:w-1/3 relative">
          <div className="aspect-w-16 aspect-h-9">
            <img
              src={require('../../images/me.jpeg')} 
              alt="Marcus Chau"
              className="rounded-lg object-cover w-full h-full shadow-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  const navItems = [
    { name: 'Ventures', path: '/#Timeline' },
    { name: 'Contact', path: '/#Contact' }
  ];

  const handleNavClick = (path) => {
    setActiveTab(path);
    const sectionId = path.split('#')[1];
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="top-0 left-0 w-full flex-1 ml-16 text-white p-4 shadow-lg z-10">
      <div className="max-w-7xl mx-auto">
        {/* Profile Section */}
        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <div className="flex items-center">
            <img
              src={require('../../images/Marcus.jpeg')}
              alt="Profile"
              className="w-16 h-16 rounded-full mr-4"
            />
            <div>
              <h1 className="text-2xl font-bold mr-2">Marcus Chau</h1>
              <p className="text-sm text-gray-400">New York City · Dallas</p>
            </div>
          </div>
          
          {/* Social Links */}
          <div className="flex space-x-4">
            <a href="https://github.com/MarcusChau" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size="lg" className="hover:text-gray-300 transition-colors" />
            </a>
            <a href="https://www.linkedin.com/in/marcus-chau/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="lg" className="hover:text-gray-300 transition-colors" />
            </a>
            <a href="mailto:marcuschau.business@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size="lg" className="hover:text-gray-300 transition-colors" />
            </a>
            <a href="https://discord.gg/dSjAXv5a" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="lg" className="hover:text-gray-300 transition-colors" />
            </a>
            <a href="https://www.youtube.com/@marcusrchau" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="lg" className="hover:text-gray-300 transition-colors" />
            </a>
          </div>
        </div>

        {/* Navigation Section */}
        <nav className="mt-6 border-t border-gray-700">
          <div className="sm:flex space-x-8 mt-4">
            {navItems.map((item) => (
              <button
                key={item.path}
                onClick={() => handleNavClick(item.path)}
                className={`px-3 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out
                  ${activeTab === item.path
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                  }`}
              >
                {item.name}
              </button>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-black text-white border-t border-gray-800">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex justify-center">
          <div className="text-gray-400 text-sm">
            © {currentYear} Marcus Chau. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import React from 'react';
import Timeline from '../../Components/Timeline';
import AboutSection from '../About';
import ContactSection from '../ContactMe';


export default function Home() {

    return (
        <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto space-y-12">
                {/* <HeroSection /> */}
                <AboutSection />
                
                <div className='relative w-full flex flex-col items-center justify-center py-24'>
                    <div className="w-px h-48 bg-white mb-16"></div>
                </div>
                {/* <EarlyLifeSection /> */}
                <div className="w-full" id='Timeline'>
                    <div className="relative w-full flex flex-col items-center justify-center mb-16">
                        <h1 className="text-white text-4xl sm:text-5xl md:text-6xl font-['DM_Serif_Display']">Timeline</h1>
                    </div>
                    <Timeline />
                </div>

                <div className='relative w-full flex flex-col items-center justify-center py-24'>
                    <div className="w-px h-48 bg-white mb-16"></div>
                </div>

                <ContactSection />
            </div>
        </div>
    )
}

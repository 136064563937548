import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:marcuschau.business@gmail.com?subject=Contact from ${formData.name}&body=${formData.message}`;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const socialLinks = [
    {
      icon: faGithub,
      href: "https://github.com/MarcusChau",
      label: "GitHub"
    },
    {
      icon: faLinkedin,
      href: "https://www.linkedin.com/in/marcus-chau/",
      label: "LinkedIn"
    },
    {
      icon: faDiscord,
      href: "https://discord.gg/dSjAXv5a",
      label: "Discord"
    },
    {
      icon: faYoutube,
      href: "https://www.youtube.com/@marcusrchau",
      label: "YouTube"
    },
    {
      icon: faEnvelope,
      href: "mailto:marcuschau.business@gmail.com",
      label: "marcuschau.business@gmail.com",
      isEmail: true
    }
  ];

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 text-white" id="Contact">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12 md:mb-16">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-['DM_Serif_Display'] mb-4">
            Get in Touch
          </h1>
          <p className="text-gray-400 text-base sm:text-lg">
            Feel free to reach out for collaborations or just a friendly chat
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {/* Contact Info */}
          <div className="space-y-6 md:space-y-8">
            <div>
              <h2 className="text-xl md:text-2xl font-semibold mb-4">Connect With Me</h2>
              <div className="space-y-4">
                {socialLinks.map((link) => (
                  <div key={link.href} className="flex items-center">
                    <a 
                      href={link.href}
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="group flex items-center space-x-3 text-gray-300 hover:text-white transition-colors"
                    >
                      <FontAwesomeIcon icon={link.icon} size="lg" className="min-w-[20px]" />
                      <span className={`${link.isEmail ? 'text-sm sm:text-base break-all' : ''}`}>
                        {link.label}
                      </span>
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h2 className="text-xl md:text-2xl font-semibold mb-4">Location</h2>
              <p className="text-gray-300">New York City · Dallas</p>
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-gray-900 p-4 sm:p-6 rounded-lg">
            <h2 className="text-xl md:text-2xl font-semibold mb-6">Send a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-2">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 sm:px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 sm:px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-3 sm:px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;